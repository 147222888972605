export const returnMenuitems = () => {
  const menuList = [
    {
      icon: "mdi-home",
      title: "Home",
      path: "/home",
      isActive: true,
    },
    {
      icon: "mdi-account-plus",
      title: "Registration",
      path: "/add-indivisual-user",
      isActive: true,
    },
    
    {
      icon: "mdi-shield-check",
      title: "KYC",
      path: "/kyc",
      isActive: true,
    },
    {
      icon: "fa fa-circle-dollar-to-slot",
      title: "Payin",
      path: "/payin",
      isActive: true,
    },
    {
      icon: "fa-solid fa-paper-plane",
      title: "Payout",
      path: "/payout",
      isActive: true,
    },
    {
      icon: "mdi-briefcase-check",
      title: "Company Registration",
      path: "/business-user-info",
      isActive: true,
    },
    {
      icon: "mdi-account",
      title: "Profile",
      path: "/profile",
      isActive: true,
    },
    {
      icon: "mdi-help-circle",
      title: "Help",
      path: "/help",
      isActive: true,
    },
  ];
  return menuList;
};
