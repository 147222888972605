var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"d-flex flex-column justify-content-center",attrs:{"id":"header"}},[_c('nav',{staticClass:"navbar nav-menu",attrs:{"id":"navbar"}},[_c('ul',{staticClass:"p-0"},[_c('li',[_c('a',{staticClass:"nav-link elevation-2",class:{
            ' nav-link active': this.$route.path == '/home',
          },on:{"click":function($event){return _vm.$router.push('/home')}}},[_c('i',{staticClass:"fa-solid fa-house-user font-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("home")))])])]),_c('li',[_c('a',{staticClass:"nav-link elevation-2",class:{
            ' nav-link active': this.$route.path == '/payins',
          },on:{"click":function($event){return _vm.$router.push('/payins')}}},[_c('i',{staticClass:"fa fa-circle-dollar-to-slot font-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("Payin")))])])]),_c('li',[_c('a',{staticClass:"nav-link elevation-2",class:{
            ' nav-link active': this.$route.path == '/payout',
          },on:{"click":function($event){return _vm.$router.push('/payout')}}},[_c('i',{staticClass:"fa-solid fa-paper-plane font-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("Payout")))])])]),_c('li',[_c('a',{staticClass:"nav-link elevation-2",class:{
            ' nav-link active': this.$route.path == '/balance',
          },on:{"click":function($event){return _vm.$router.push('/balance')}}},[_c('i',{staticClass:"fa-solid fa-file-lines font-icon ps-1"}),_c('span',[_vm._v(_vm._s(_vm.$t("balance_history")))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }